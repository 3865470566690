var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("activity-SideMenu"),
      _c(
        "div",
        {
          staticClass: "content-box",
          class: { "content-collapse": _vm.collapse }
        },
        [
          _c("activity-Header"),
          _c(
            "div",
            { staticClass: "content" },
            [_vm.isRouterAlive ? _c("router-view") : _vm._e()],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }