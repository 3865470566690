<template>
  <div class="wrapper">
    <activity-SideMenu />
    <div class="content-box" :class="{'content-collapse':collapse}">
      <activity-Header />
      <div class="content">
        <router-view v-if="isRouterAlive"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import ActivityAjax from "@/utils/https/modules/Activity.request.js";

export default {
  name: "ActivityHome", // 活动二级入口组件
  provide() {
    return {
      reload: this.reload
    };
  },
  data() {
    return {
      isRouterAlive: true,
      collapse: false,
      activityID:0,
      baseInfo:{}
    };
  },
  components: {
    ActivitySideMenu: () => import("@/components/ActivitySideMenu.vue"),
    ActivityHeader: () => import("@/components/ActivityHeader.vue"),
  },
  watch: {
    $route(to, from) {
      console.log(to, from);
    }
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    // 获取活动信息
    async getActivityDetails() {
      try {
        const { data } = await ActivityAjax.getActivityDetails(this.activityID);
        this.baseInfo = Object.assign(this.baseInfo, data);
        this.$bus.$emit("actName", this.baseInfo.actName);
        this.$bus.$emit("actUrl", this.baseInfo.actUrl);
        this.$bus.$emit("qrcodePoster", this.baseInfo.actPoster);
        // console.log(this.baseInfo);
      } catch (error) {
        this.$message.error(error);
      }
    },
  },
  async created() {
    this.$bus.$on("collapse", msg => {
      this.collapse = msg;
    });
    this.activityID = this.$route.params.activityID;
  },
  async mounted () {
    this.$nextTick(() => {
      this.getActivityDetails();
    });
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  .floatBtn {
    position: absolute;
    right: 10px;
    bottom: 60px;
    z-index: 9999;
    div {
      margin-bottom: 10px;
    }
  }
}
</style>
